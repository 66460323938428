<template>
  <b-col md="12">
    <p class="text-dark font-size-h5 font-weight-bold">
      Ghi chú
    </p>
    <div class="d-flex flex-column flex-md-row mb-5">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between font-size-md mb-3">
          <div class="d-flex flex-column">
            <span class="font-weight-bold mr-10">
              Số điện thoại chuyển:
            </span>
            <strong>{{ ticket.body.phoneNumberTransfer }}</strong>
          </div>
          <div class="d-flex flex-column">
            <span class="font-weight-bold mr-10">
              Số điểm chuyển:
            </span>
            <strong>{{ ticket.body.point }}</strong>
          </div>
          <div class="d-flex flex-column">
            <span class="font-weight-bold mr-10">
              Số điện thoại nhận:
            </span>
            <strong>{{ ticket.body.phoneNumberReceive }}</strong>
          </div>
        </div>
      </div>
    </div>
  </b-col>
</template>
<script>
export default {
    props:['ticket']
};
</script>