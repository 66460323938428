<template>
  <div class="ticket">
    <KTCodePreview v-bind:title="'Dánh sách các phiếu yêu cầu hỗ trợ'">
      <template v-slot:preview>
        <!-- Header session -->
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row class="mb-5">
              <b-col class="pl-0" md="3">
                <b-input
                  v-model="id"
                  placeholder="Nhập ID phiếu yêu cầu"
                  size="sm"
                ></b-input>
              </b-col>
              <b-col md="3">
                <treeselect
                  :options="listTicketType"
                  :multiple="false"
                  placeholder="Chọn loại phiếu"
                  noResultsText="Không có kết quả"
                  :match-keys="['label']"
                  openDirection="bottom"
                  :clearable="false"
                  v-model="selectedTicketType"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span :title="node.label"> {{ node.label }}</span>
                  </label>
                </treeselect>
              </b-col>
              <b-col md="3">
                <b-form-select
                  size="sm"
                  required
                  class="select-style"
                  placeholder="Chọn trạng thái"
                  v-model="selectedStatus"
                  :options="listStatus"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  filter
                ></b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1" style="padding-left: 0">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="fetchListTicket()"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </div>
        <!-- End of Header session -->
        <b-row>
          <b-col cols="12">
            <b-table :items="items" :fields="fields" bordered>
              <template v-slot:cell(userCreateTicket)="row">
                <div class="text-center" v-if="row.item.userCreateTicket">
                  <p class="mb-0">
                    {{ row.item.userCreateTicket.employee.fullName }}
                  </p>
                  <p
                    class="mb-0"
                    v-if="row.item.userCreateTicket.employee.code"
                  >
                    {{ row.item.userCreateTicket.employee.code }}
                  </p>
                </div>
              </template>

              <template v-slot:cell(summary)="row">
                <span class="sumary-text"> {{ row.item.summary }} </span>
              </template>

              <template v-slot:cell(store)="row">
                <span
                  v-if="
                    row.item.userCreateTicket &&
                      row.item.userCreateTicket.employee.store
                  "
                >
                  {{ row.item.userCreateTicket.employee.store.name }}
                </span>
              </template>

              <template v-slot:cell(userAssigneeTicket)="row">
                <div class="text-center" v-if="row.item.userAssigneeTicket">
                  <p class="mb-0">
                    {{ row.item.userAssigneeTicket.employee.fullName }}
                  </p>
                  <p
                    class="mb-0"
                    v-if="row.item.userAssigneeTicket.employee.code"
                  >
                    {{ row.item.userAssigneeTicket.employee.code }}
                  </p>
                </div>
                <div class="text-center" v-else>
                  <p class="mb-0">--</p>
                </div>
              </template>

              <template v-slot:cell(status)="row">
                <div class="text-center">
                  <span
                    style="width: max-content"
                    v-bind:class="{
                      'badge badge-warning text-white':
                        row.item.status === TICKET_STATUS.NEW,
                      'badge badge-primary text-white':
                        row.item.status === TICKET_STATUS.RECORDED_INFOMATION,
                      'badge badge-light':
                        row.item.status === TICKET_STATUS.PROCESSING,
                      'badge badge-success':
                        row.item.status === TICKET_STATUS.COMPLETED,
                      'badge badge-dark':
                        row.item.status === TICKET_STATUS.REJECT,
                    }"
                  >
                    {{ TICKET_STATUS_NAME[row.item.status] }}
                  </span>
                </div>
              </template>

              <template v-slot:cell(actions)="row">
                <b-dropdown size="sm" id="dropdown-left" no-caret right>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem; padding-right: 0px"
                      class="flaticon2-settings"
                    ></i>
                  </template>
                  <b-dropdown-item @click="detailItem(row.item)">
                    <span style="color: #3f4254; font-size: 12px"></span>
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-pen icon-color"
                    ></i>
                    &nbsp; Chi tiết
                  </b-dropdown-item>
                  <b-dropdown-item @click="editItem(row.item)">
                    <span style="color: #3f4254; font-size: 12px"></span>
                    <i
                      class="fas fa-sync-alt icon-color"
                      style="font-size: 1rem"
                    >
                    </i>
                    &nbsp; Đổi trạng thái
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <div class="paging">
          <div class="total">
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItems }}
            </p>
          </div>
          <div class="paging-nav">
            <b-pagination-nav
              class="custom-pagination"
              :link-gen="linkGen"
              v-show="totalPages >= 2"
              :number-of-pages="totalPages"
              use-router
              @change="fetchListTicket"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </div>
        </div>
        <b-col></b-col>
        <b-col></b-col>

        <!-- Cấu hình chức vụ - số giờ công -->
        <div class="add-model">
          <b-modal ref="my-modal" hide-footer title="Cập nhật trạng thái phiếu">
            <b-row>
              <b-col>
                <b-form-group>
                  <label>Trạng thái</label>
                  <treeselect
                    :options="statusOptions"
                    :multiple="false"
                    placeholder="Chọn trạng thái"
                    noResultsText="Không có kết quả"
                    :match-keys="['label']"
                    openDirection="bottom"
                    :clearable="false"
                    v-model="ticketUpdate.status"
                  >
                    <label
                      slot="option-label"
                      slot-scope="{ node, labelClassName }"
                      :class="labelClassName"
                    >
                      <span :title="node.label"> {{ node.label }}</span>
                    </label>
                  </treeselect>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="ticketUpdate.status === TICKET_STATUS.REJECT">
              <b-col>
                <b-form-group>
                  <label>Lý do từ chối</label>
                  <b-form-textarea
                    size="sm"
                    class="input-style"
                    :placeholder="'Thêm lý do từ chối'"
                    :rows="6"
                    :max-rows="6"
                    v-model="ticketUpdate.reason"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>

            <b-button
              class="mt-2"
              variant="btn btn-primary"
              block
              @click="onSaveModal"
              >Lưu</b-button
            >

            <b-button
              class="mt-3"
              variant="btn btn-outline-secondary"
              block
              @click="hideModal"
              >Đóng</b-button
            >
          </b-modal>
        </div>
      </template>
    </KTCodePreview>
    <DetailTicket
      :params="ticket"
      :image="attachments"
      @getListTicket="handleGetListTicket"
    />
  </div>
</template>

<style scoped>
.paging {
  display: flex;
  justify-content: space-between;
}
.form-group label {
  font-weight: 500;
}
.submit-config button {
  font-weight: 600;
  width: 70px;
}
.submit-config button:nth-child(2) {
  margin-left: 10px;
}
.transport-config-header {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-end;
}
.sumary-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ticket /deep/ .vue-treeselect__control {
  border-radius: 0.28rem;
  border: 1px solid #ced4da;
  height: 2.5rem;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { makeToastSuccess, makeToastFaile } from '@/utils/common';
import DetailTicket from './Detail.vue';
import { TICKET_STATUS_NAME } from '@/utils/enum-name';
import { TICKET_STATUS } from '@/utils/enum';
import axios from 'axios';
import { cmdUrl } from '@/utils/apiUrl';
import JwtService from '@/core/services/jwt.service';
export default {
  components: {
    KTCodePreview,
    Treeselect,
    DetailTicket,
  },
  data() {
    return {
      TICKET_STATUS_NAME,
      TICKET_STATUS,
      statusOptions: [
        { id: 0, label: 'Chọn trạng thái' },
        { id: 1, label: 'Ghi nhận thông tin' },
        { id: 2, label: 'Đang xử lí' },
        { id: 3, label: 'Hoàn thành' },
        { id: 4, label: 'Từ chối' },
      ],
      stores: [],
      storeOptions: [],
      totalPages: 1,
      totalItems: 0,
      page: 1,
      currentPage: 1,
      items: [],
      id: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '10%',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
        {
          key: 'TicketTypeSetting.name',
          label: 'Loại phiếu',
          thStyle: { color: '#181c32', width: '280px' },
        },
        {
          key: 'userCreateTicket',
          label: 'Người tạo',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
        },
        {
          key: 'summary',
          label: 'Nội dung',
          thStyle: { color: '#181c32', width: '480px', textAlign: 'center' },
        },
        {
          key: 'store',
          label: 'Cửa hàng',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
        },
        {
          key: 'userAssigneeTicket',
          label: 'Người hỗ trợ',
          thStyle: { color: '#181c32', width: '240px', textAlign: 'center' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { color: '#181c32', width: '120px', textAlign: 'center' },
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
      selectedStatus: -1,
      listStatus: [
        { id: -1, name: 'Chọn trạng thái' },
        { id: 0, name: 'Mới' },
        { id: 1, name: 'Ghi nhận thông tin' },
        { id: 2, name: 'Đang xử lí' },
        { id: 3, name: 'Hoàn thành' },
        { id: 4, name: 'Từ chối' },
      ],
      selectedTicketType: -1,
      listTicketType: [{ id: -1, label: 'Chọn loại phiếu' }],
      limit: 10,
      onSaving: false,
      search: '',
      ticket: null,
      ticketUpdate: {
        id: null,
        status: -1,
        reason: null,
      },
      attachments: [],
    };
  },
  created() {
    this.fetchListTicket();
    this.fetchListTypeTicket();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Danh sách phiếu yêu cầu hỗ trợ',
        route: 'ticket',
      },
    ]);
  },
  methods: {
    updateStatus() {
      const payloadUpdateTicket = {
        status: this.ticketUpdate.status,
        id: this.ticketUpdate.id,
      };

      if (payloadUpdateTicket.status === TICKET_STATUS.REJECT) {
        if (!this.ticketUpdate.reason) {
          makeToastFaile('Vui lòng nhập lí do từ chối');
          return;
        }
        payloadUpdateTicket['reason'] = this.ticketUpdate.reason;
      }

      if (!payloadUpdateTicket['status']) {
        makeToastFaile('Vui lòng chọn trạng thái');
        return;
      }

      if (!TICKET_STATUS_NAME[payloadUpdateTicket.status]) {
        makeToastFaile('Trạng thái không hợp lệ');
        return;
      }

      ApiService.post('/v2/tickets/update', payloadUpdateTicket)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 200) {
            this.fetchListTicket();
            makeToastSuccess(message);
            this.hideModal();
          } else {
            makeToastFaile(message);
          }
        })
        .catch(({ response }) => {
          console.log({ response });
          const { message } = response.data;
          makeToastFaile(message);
        });
    },

    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    onSaveModal() {
      this.updateStatus();
    },
    handleGetListTicket() {
      this.fetchListTicket();
    },
    fetchListTicket: async function() {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      const param = {
        page: this.page,
        pageSize: 10,
        search: this.search,
        id: this.id,
      };

      if (this.selectedTicketType !== -1) {
        param['type'] = this.selectedTicketType;
      }
      if (this.selectedStatus !== -1) {
        param['status'] = this.selectedStatus;
      }
      const paramQuery = {
        params: param,
      };

      ApiService.query('/v2/tickets', paramQuery).then((response) => {
        const dataResponse = response.data.data;
        this.items = [];
        this.totalPages =
          dataResponse.totalPage > 0 ? dataResponse.totalPage : 1;
        this.totalItems = dataResponse.totalRow;
        this.currentPage = param.page;
        this.items = [...dataResponse.dataset];
      });
    },
    async fetchListTypeTicket() {
      ApiService.get('/v2/tickets/type').then((response) => {
        const dataResponse = response.data.data;
        for (const item of dataResponse) {
          this.listTicketType.push({ id: item.code, label: item.name });
        }
      });
    },
    editItem: function(item) {
      if (item) {
        this.ticketUpdate.id = item.id;
        this.ticketUpdate.status = item.status;
        this.ticketUpdate.reason = item.reason;
        this.showModal();
      }
    },
    detailItem: async function(item) {
      this.ticket = item;
      const listImage = JSON.parse(this.ticket.extra);
      if (listImage && listImage.length) {
        await this.getImage(listImage);
      }
      this.$bvModal.show('detail-ticket');
    },
    async getImage(image) {
      this.attachments = await Promise.all(
        image.map(async (item) => {
          const fileName = item;
          const resp = await axios.get(
            `${cmdUrl.File.download}?filename=${fileName}`,
            {
              responseType: 'arraybuffer',
              headers: {
                Authorization: `Bearer ${JwtService.getToken()}`,
              },
            },
          );
          const contentType = resp.headers['content-type'];
          const base64 = `data:${contentType};base64,${Buffer.from(
            resp.data,
          ).toString('base64')}`;
          return {
            url: base64,
            fileName,
          };
        }),
      );
    },
  },
};
</script>
